import React from 'react';
import { Link } from 'react-router-dom';
import './css/NavBar.css'
import logo from "./img/attempt.svg"

const NavBar = () => {
    return (        
        <nav role="navigation" aria-label="main menu">
            <ul>
                <li><img src={logo} width="200" height="50"/></li>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/about'>About</Link></li>
                <li><Link to='/cafe'>Cafe</Link></li>
                <li><Link to='/cats'>Cats</Link></li>
                <li><Link to='/shop'>Shop</Link></li>
                <li class="siteLanguage"><Link to='/Norwegian'>Mode</Link></li>
                <li class="siteLanguage"><Link to='/Norwegian'>Norwegian</Link></li>
            </ul>
        </nav>
    
    )
}

export default NavBar;