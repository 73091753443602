import React from 'react';

const About = () => (
    <>
    <h1>About</h1>
    <p>
        UwU
    </p>
    <p>
        KONEKO-CHAN rEEEE
    </p>
    </>
);

export default About;