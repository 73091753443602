import Homepage from './pages/Homepage.js';
import About from './pages/About.js';
import Cafe from './pages/Cafe.js';
import Cats from './pages/Cats.js';
import Shop from './pages/Shop.js';
import Norwegian from './norsk/Hjem.js';
import {
  BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './css/App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NavBar from './NavBar.js';
import Footie from './Footie.js';

function App() {
    return (
      <Router>
        <div className="App">
        <link rel="stylesheet" href="App.css"></link>
          <NavBar />
          <div id= "page-body">
            <Routes>
              <Route exact path="/" element={<Homepage />} />
              <Route path="/About" element={<About />} />
              <Route path="/Cafe" element={<Cafe />} />
              <Route path="/Cats/:name" element={<Cats />} />
              <Route path="/Shop" element={<Shop />} />
            </Routes>
          </div>
          <Footie />
        </div>
      </Router>
    );
}

export default App;