import React from 'react';

const Homepage = () => (
    <>
    <h1>Welcome to Koneko</h1>
    <p>
        English version
    </p>
    <p>
        OwO
    </p>
    </>
);

export default Homepage;