import React from 'react';
import { useParams } from 'react-router';

export const Cats = () => {
    const { name } = useParams();
    return(
        <>
            <h1>cope</h1>
        </>
    );
};

export default Cats;