import React from 'react';

const Cafe = () => (
    <>
    <h1>Cafe</h1>
    <p>
        UwU
    </p>
    <p>
        KONEKO-CHAN rEEEE
    </p>
    </>
);

export default Cafe;