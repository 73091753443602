import React from 'react';
import './css/footie.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Footie = () => {
    return (
        <footer>
                <div>
                    <div>
                        <div>
                            <h4>Contact Me</h4>
                            <ul class="centered">
                                <li><FontAwesomeIcon icon="faPhone"/>number</li>
                                <li><i class="faEnvelope"></i>contact@koneko.no</li>
                                <li><FontAwesomeIcon icon="fa-solid fa-location-dot" />location</li>
                            </ul>
                        </div>
                        <div>
                            <h4>Social Networks</h4>
                            <ul class="centered">
                                <li><a class="social-icon facebook" href="#"><i class="fa-brands fa-facebook"></i></a></li>
                                <li><a class="social-icon github" href="https://github.com/TinyEmi"><i class="fa-brands fa-github"></i></a></li>
                                <li><a class="social-icon instagram" href="#"><i class="fa-brands fa-instagram"></i></a></li>
                                <li><a class="social-icon linkedin" href="#"><i class="fab fa-linkedin"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            <small>Copyright &copy; By Emilija Rauktyte 2021</small>
        </footer>
    )
}

export default Footie;